import { debounceTime } from '@proman/rxjs-common';
import { Component, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Search } from '@proman/services/search.service';
import { isTouchDevice } from '@proman/utils';

import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'pm-search',
    template: `
        <div #element class="Search" fxLayout="row" fxLayoutAlign="center center">
            <pro-autoc [control]="inputCtrl"
                       [config]="{ label: 'search', preventClear: true }"
                       [options]="data"
                       (onChange)="select($event)"></pro-autoc>
            @if (inputCtrl.getRawValue()) {
                <pro-btn icon="times" (onClick)="inputCtrl.setValue(''); select(null)"></pro-btn>
            }
        </div>
    `,
    styles: [`

    `]
})

export class SearchComponent implements AfterViewInit {
    @ViewChild('element', { static: true }) element: ElementRef;
    inputCtrl: UntypedFormControl;
    data: Promise<any[]> = Promise.resolve([]);
    wrapper: any;
    subscriber: any;

    constructor(
        private search: Search,
        private Router: Router,
        private cd: ChangeDetectorRef,
    ) {
        this.inputCtrl = new UntypedFormControl();
    }

    ngAfterViewInit() {
        this.wrapper = { model: null, searchText: '' };

        this.search.registerForm(this.wrapper);
        this.subscribe(null);

        this.Router.events
            .subscribe((event: NavigationStart|NavigationEnd) => {
                if (event instanceof NavigationStart) this.unsubscribe();
                if (event instanceof NavigationEnd) this.subscribe(event);
            });

    }

    subscribe = (stateChangeEvent: any) => {
        if (stateChangeEvent) setTimeout(this.setInputFocus);

        this.subscriber = this.inputCtrl.valueChanges.pipe(
            debounceTime(1000))
            .subscribe((val: any) => {
                if (typeof val === 'object') return;

                this.data = this.search.exec(val);
                this.cd.markForCheck();
            });
    };

    unsubscribe = () => {
        this.data = this.search.exec(null);
        this.subscriber.unsubscribe();
        this.inputCtrl.setValue('');
        this.search.unregister();
    };

    setInputFocus = () => {
        const inputElement = this.element.nativeElement.querySelector('input');
        const _isTouchDevice = isTouchDevice();

        if (!_isTouchDevice && inputElement && !(window as any).Cypress) inputElement.focus();
    };

    select(item: any) {
        (item) ? this.search.resultClicked(item) : this.search.exec(null);
    }

}
