import { filter } from '@proman/rxjs-common';
import {
  Component,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  SimpleChanges, OnChanges, AfterViewInit, Inject
} from '@angular/core';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { MenuService } from '../services/menu.service';
import { debounce, delay } from '@proman/utils';
import { PromanStateService } from '../services/proman-state.service';
import { NavigationEnd, Router } from '@angular/router';
import { UI_COMPACT_MENU } from '@proman/services/ui-preferences.service';
import { Store } from '@ngrx/store';
import { getCurrUserUiPrefs } from '@proman/store/curr-user';
import { UserMenu, UserMenuGroup } from '@proman/interfaces/entity-interfaces';

@Component({
  selector: 'pm-overlay-menu',
  template: `
    @if (menuActive) {
      <pro-btn icon="times" style="z-index: 1000" class="Overlay-Menu--Show-Button" theme="primary"
               (onClick)="menuActive = false; updateView()"></pro-btn>
      <div fxLayout="row wrap" fxLayoutAlign="center center" class="loading">
        <div class="Overlay-Menu--Items-Container" fxLayout="row wrap">
          <pm-account fxFlex="50"></pm-account>
          @for (category of menu; track $index) {
            <div class="Menu" fxFlex="50"
                 pmPadding
                 fxLayout="column">
              <a class="Menu-category"
                 [ngClass]="{ 'active': checkActiveCategory(category) }"
                 fxLayout="column"
                 fxLayoutAlign="center center"
                 [matMenuTriggerFor]="overlaySubMenu"
                 (click)="selectCategory(category)">
                @if (category.icon) {
                  <fa class="Category-icon" size="4x" [custom]="'fw'" [name]="category.icon"></fa>
                }
                <span class="Menu-category--name"
                      [attr.data-menu]="category.name">{{ category.name | translate }}</span>
              </a>
              <mat-menu #overlaySubMenu="matMenu" fxFlex fxLayout="column">
                @if (isVisibleItems(category) && ((!isCompactMenu && category.tabs) || (isCompactMenu && previewCategory))) {
                  @for (tab of category.tabs; track $index) {
                    @if (isVisibleTab(category, tab)) {
                      <button [ngClass]="{ 'active': tab.state === activeCategoryTab }"
                              [attr.data-submenu]="tab.name"
                              mat-button fxFlexFill
                              [docsId]="tab.name"
                              (click)="navigateToState(tab.state)">{{ tab.name | translate }}
                      </button>
                    }
                  }
                }
              </mat-menu>
            </div>
          }
        </div>
      </div>
    } @else {
      <pro-btn class="Overlay-Menu--Show-Button" faClass="fad"
               faStyles="--fa-secondary-color: black; --fa-secondary-opacity: 1.0" icon="bars-staggered" theme="accent"
               (onClick)="menuActive = true"></pro-btn>
    }
  `,
  // animations: [
  //   trigger('shrinkOut', [
  //     state('in', style({ height: 0 })),
  //     transition('void => *', [
  //       style({ height: 0 }),
  //       animate(300, style({ height: '*' }))
  //     ]),
  //     transition('* => void', [
  //       style({ height: '*' }),
  //       animate(100, style({ height: 0 }))
  //     ])
  //   ])
  // ],
  styles: [`
    pm-account {
      z-index: 999;
    }
    
    .Overlay-Menu--Show-Button {
      position: fixed;
      margin: auto;
    }
    
    .loading {
      position: fixed;
      z-index: 999;
      height: 100%;
      width: 100%;
      overflow: visible;
      margin: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    /* Transparent Overlay */
    .loading:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));

      background: -webkit-radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
    }

    /* :not(:required) hides these rules from IE9 and below */
    .loading:not(:required) {
      /* hide "loading..." text */
      font: 0/0 a;
      color: transparent;
      text-shadow: none;
      background-color: transparent;
      border: 0;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class OverlayMenuComponent implements OnDestroy, OnChanges, OnInit, AfterViewInit {
  menu: any;
  previewCategory: any;
  activeCategory: any;
  activeCategoryTab: any;
  isCompactMenu: boolean;
  isMouseIn: boolean;
  closeDebounce: any;
  unassigned: UserMenu[];
  grouped: UserMenuGroup[];
  menuActive = true;

  constructor(
    private Menu: MenuService,
    private cd: ChangeDetectorRef,
    private PromanState: PromanStateService,
    private Router: Router,
    @Inject(Store) private store: Store
  ) {
    this.closeDebounce = debounce(() => {
      if (!this.isMouseIn) {
        if (!this.isCompactMenu) this.previewCategory = null;
        this.updateView();
      }
    }, 2000);

    document.body.addEventListener('keyup', (event) => {
      if (event.key === 'Escape') {
        console.log('event!:', event, this.menuActive);
        this.menuActive = !this.menuActive;
        this.cd.detectChanges();
      }
    });
  }

  ngOnInit() {

    if (!this.Menu.items.length) this.Menu.init();

    this.Menu.updateActiveElements();

    this.menu = this.Menu.items;

    this.updateActiveElements();

    this.Router.events.pipe(
      filter((event) => (event instanceof NavigationEnd)))
      .subscribe(() => this.updateActiveElements());

    this.updateView();
  }

  async ngAfterViewInit() {
    await delay(100);
    this.store.select(getCurrUserUiPrefs)
      .subscribe((uiPrefs) => {
        this.isCompactMenu = uiPrefs[UI_COMPACT_MENU];
        this.updateView();
      });

  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnDestroy() {
    this.Menu.destroy();
  }

  isVisibleItems(category: any) {
    return (!this.previewCategory && this.activeCategory === category) || this.previewCategory === category;

  }

  selectCategory(category: any) {
    (this.previewCategory === category) ?
      this.previewCategory = null :
      this.previewCategory = category;

    if (!category.tabs) this.navigateToFirstChildState(category);

  }

  selectUserMenuCategory(category: UserMenuGroup) {
    (this.previewCategory === category) ?
      this.previewCategory = null :
      this.previewCategory = category;

    if (!category.items) this.navigateToFirstUserMenuChildState(category);

  }

  navigateToFirstChildState(category: any) {
    this.menuActive = false;
    this.PromanState.to(category.state);

    this.activeCategory = category;
    this.activeCategoryTab = category.state;
  }

  navigateToFirstUserMenuChildState(category: UserMenuGroup) {
    this.PromanState.to(category.state);

    this.activeCategory = category;
    this.activeCategoryTab = category.state;
  }

  updateActiveElements = () => {

    if (this.Menu.activeCategory) {
      this.activeCategory = this.Menu.activeCategory;

    }

    if (this.Menu.activeCategoryTab) {
      this.activeCategoryTab = this.Menu.activeCategoryTab;

    }

    this.previewCategory = null;

    this.updateView();

  };

  isVisibleTab = (category: any, tab: any) => {
    if (this.isCompactMenu && !this.previewCategory) return false;
    if (tab.state === this.activeCategoryTab) return true;
    if (category === this.previewCategory) return true;

    this.updateView();
  };

  updateView = () => this.cd.markForCheck();

  handleMouseLeave = () => {
    this.isMouseIn = false;

    if (this.isCompactMenu) {
      this.closeDebounce();
    }
  };

  handleMouseEnter = () => {
    this.isMouseIn = true;
    this.closeDebounce.cancel();
  };

  handleUserMenu(menu: UserMenu) {
    if (menu.state.includes(window.location.origin)) {
      const state = '/' + menu.state.split('//')[1].split('/').slice(1).join('/');
      this.Router.navigate([state]);
    } else {
      window.location.href = menu.state;
    }
  }

  navigateToState = (state: string) => {
    this.menuActive = false;
    this.PromanState.to(state);
  };

  checkActiveCategory = (category: any) => category.tabs?.some((tab: {
    state: string
  }) => tab.state === this.activeCategoryTab);

  checkActiveUserMenuCategory = (category: UserMenuGroup) => category.items?.some((tab: {
    state: string
  }) => tab.state === this.activeCategoryTab);
}
