import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, tap } from '@proman/rxjs-common';
import {
    SystemOptionsActions,
} from '@proman/store/system-options/system-options.actions';
import { UpdateComponent } from '@frontend/shared/components/update.component';
import { CurrUserActions } from '@proman/store/curr-user';
import {
  EmployeeDocumentsReadDialogComponent
} from '../../employees/components/employee-documents-read-dialog.component';
import { Entity } from '@proman/services/entity.service';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { pipe } from 'rxjs';
import { Person } from '@proman/interfaces/entity-interfaces';


@Injectable()
export class SystemOptionsFrontendEffects {

    triggerUpdate$ = createEffect(() => this.actions$
            .pipe(
                ofType(SystemOptionsActions.SetUpdateStatus),
                tap((action: { payload: boolean }) => {
                    const value = action.payload;
                    if (value) {
                        const hostView = this.componentFactoryResolver.resolveComponentFactory(UpdateComponent)
                            .create(this.injector).hostView;

                        this.applicationRef.attachView(hostView);

                        document.body.appendChild((hostView as EmbeddedViewRef <any> ).rootNodes[0] as HTMLElement);

                    }

                })
            ),
        { dispatch: false }
    );

    checkDocumentsRead$ = createEffect(() => this.actions$
        .pipe(
          ofType(CurrUserActions.SetCurrUser),
          pipe(distinctUntilChanged()),
          tap((action: { payload: CurrUser }) => {
            const currUser: CurrUser = action.payload;
            if (currUser) {
              if (currUser.type === 'employee' && this.userDocumentsCheck !== this.generatePersonName(currUser.person)) {
                this.userDocumentsCheck = this.generatePersonName(currUser.person);
                this.Entity.get('employee_document')
                  .getByEmployee(currUser.person.id)
                  .then(async (response) => {
                    if (response) {
                      const relevantIds = response.documents.map((doc) => doc.id);
                      const relevantReads = response.reads.filter((read) => relevantIds.includes(read.documentId));
                      if (response.documents.length > relevantReads.length) {
                        const employee = await this.Entity.get('employee').get({ id: currUser.person.id, join: ['documentsRead'] });
                        this.Dialog.open(EmployeeDocumentsReadDialogComponent, { employee });
                      }
                    }
                  });
              }

            }

          })
        ),
      { dispatch: false }
    );

    generatePersonName = (person: Person) => `${person.firstName}${[person.lastName]}`;


    userDocumentsCheck: string;



    constructor(
        private actions$: Actions,
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private injector: Injector,
        private Dialog: Dialog,
        private Entity: Entity,
    ) {

    }

}
