import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { FaIcons } from '../../core/icons';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { Template } from '@proman/interfaces/entity-interfaces';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';

@Component({
    selector: 'pm-add-action-dialog',
    template: `
        <pro-dialog-title title="add_action"></pro-dialog-title>
        <div mat-dialog-content>
            <mat-list>
                <mat-list-item *ngFor="let item of items" class="Clickable" [ngClass]="{ 'selected': item.id === selectedId }" (click)="selectedId = item.id;selectedItem = item">
                    <h4 mat-line>{{ item.name }}</h4>
                </mat-list-item>
                <pro-no-records *ngIf="!items || !items.length"></pro-no-records>
                <hr>
            </mat-list>

            <div class="Padding">
                <pm-icon-select [value]="icon"
                                (onChange)="setIcon($event)"></pm-icon-select>
            </div>
        </div>
        <pro-dialog-actions (callback)="confirm()"
                           [isCallbackDisabled]="!selectedId"
                           [variant]="'confirm'"></pro-dialog-actions>
    `,
    styles: [`
        .selected {
            background: rgba(33,150,243, 0.5);
        }
    `]

})

export class DashboardAddActionTemplateButtonDialogComponent {
    items: any[];
    selectedId: any;
    selectedItem: any;
    icon: any;
    currUser: CurrUser;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private store: Store,
        private Entity: Entity,
        private dialogRef: MatLegacyDialogRef<DashboardAddActionTemplateButtonDialogComponent>,
    ) {
        // this.currUser =

        this.store.select(getCurrUser)
            .subscribe((value) => {
                this.currUser = value;
            });

        if (data.type === 'action') {
            this.Entity.get('button_listener')
                .search()
                .then((response: any[]) => this.items = response);

        } else {
            const templateEntity = this.Entity.get('template');
            Promise
                .all([
                    templateEntity.search({ useBuilder: true }),
                    templateEntity.search({ context: 'xyz_report' })
                ])
                .then((values: [Template[], Template[]]) => {
                   this.items = [].concat(values[0], values[1]);
                });

        }

        this.icon = FaIcons[Math.floor(Math.random() * FaIcons.length)];
    }

    confirm() {
        const request = this.data.type === 'action' ?
            {
                config: JSON.stringify({ action: this.selectedId, name: this.selectedItem.name }),
                owner: this.currUser.person.id,
                type: 'button_action',
                icon: this.icon
            } :
            this.selectedItem.useBuilder ?
                {
                    config: JSON.stringify({ template: this.selectedId, name: this.selectedItem.name }),
                    owner: this.currUser.person.id,
                    type: 'template_preview',
                    icon: this.icon
                } :
                {
                    config: JSON.stringify({ template: this.selectedId, name: this.selectedItem.name }),
                    owner: this.currUser.person.id,
                    type: 'template_xyz',
                    icon: this.icon
                };

        this.Entity.get('dashboard')
            .create(request)
            .then(() => this.dialogRef.close(1));
    }

    setIcon = (value: any) => {
        this.icon = value;
    };

}
