import {
    Component,
    OnDestroy,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    OnInit,
} from '@angular/core';
import { Location } from '@angular/common';
import { MediaChange, MediaObserver } from 'ngx-flexible-layout';
import { Subscription } from 'rxjs';
import { UI_TABLE_SAVED_FILTERS, UiPreferencesService } from '@proman/services/ui-preferences.service';
import { Dialog } from '../services/dialog.service';
import { TableCacheService } from '@proman/table/services/table-cache.service';
import { FilterService } from '@proman/services/filter.service';
import { DashboardSettingsDialogComponent } from '../../dashboards/components/dashboard-settings-dialog.component';
import { DashboardHyperlinkDialogComponent } from '../../dashboards/components/dashboard-hyperlink-dialog.component';
import { FaIcons } from '../../core/icons';
import { Entity } from '@proman/services/entity.service';
import { ObservablesService } from '@proman/services/observables.service';
import { PromanStateService } from '../services/proman-state.service';
import { DashboardAddActionTemplateButtonDialogComponent } from '../../dashboards/components/dashboard-add-action-template-button-dialog.component';
import { TableHelperService } from '@proman/table/services/table-helper.service';
import { PreferencesService } from '@proman/services/preferences.service';
import { Router } from '@angular/router';
import { TextPreviewDialogComponent } from './text-preview-dialog.component';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { isNumber, snakeCase } from '@proman/utils';
import { UrlReadDialogComponent } from './url-read-dialog.component';
import { TemplateEntityInterface } from '@proman/resources/template';
import { ViewPdfDialogComponent } from '../../orders/components/view-pdf-dialog.component';
import { DashboardEmojiDialogComponent } from '../../dashboards/components/dashboard-emoji-dialog.component';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { ACL } from '@proman/services/acl.service';
import { AddUserMenuItemDialogComponent } from '../../shared-dialogs/components/add-user-menu-item-dialog.component';
import {
    DashboardQuickSearchDialogComponent
} from '../../dashboards/components/dashboard-quick-search-dialog.component';
import { getPublicSystemOptions } from '@proman/store/system-options';
import { Specialisation } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'pm-header',
    template: `
        @if (hasSmartonDebts && checkAdmin()) {
            <pro-warning>{{ 'there_are_unpaid_smarton_invoices' | translate }}</pro-warning>
        }
        <mat-toolbar class="Header"
                     color="primary">

            @if (isMobile && !currUser.bookkeepingUser) {
                <pro-btn class="LayoutToggleButton"
                         (onClick)="toggleMenu()"
                         icon="arrow-from-left"
                         theme="accent"></pro-btn>
            }

            @if (!companyConnection) {
                @for (dashboard of dashboards; track $index) {
                    <pro-btn (onClick)="callAction(dashboard)"
                             [icon]="getIcon(dashboard)"
                             theme="primary"
                             class="HeaderDashboardLink"
                             [tooltip]="getTooltip(dashboard)"
                             [tooltipPosition]="'right'"
                    ></pro-btn>
                    <!--                [pmOverlay]="{ type: 'button', data: getTooltip(dashboard) }"-->
                }
            }

            <div fxFlex></div>
            @if (!isMobile) {
                <pm-search></pm-search>
            }
            <mat-menu #actionsMenu="matMenu">
                <button mat-menu-item
                        (click)="addHyperlink()">
                    {{ 'add_menu_item' | translate }}
                </button>
                <button mat-menu-item
                        (click)="addAsFilter()">
                    {{ 'add_current_view' | translate }}
                </button>
                <button mat-menu-item
                        (click)="addAction()">
                    {{ 'add_action' | translate }}
                </button>
                @if (ACL.check('reports.master')) {
                    <button mat-menu-item
                            (click)="addTemplatePreview()">
                        {{ 'add_template_preview' | translate }}
                    </button>
                }
                <button mat-menu-item
                        (click)="changeSettings()">
                    {{ 'settings' | translate }}
                </button>
                <button mat-menu-item
                        (click)="addToMenu()">
                    {{ 'add_to_personal_menu' | translate }}
                </button>
                <button mat-menu-item
                        (click)="quickSearch()">
                    {{ 'quick_search' | translate }}
                </button>
            </mat-menu>

            @if (isMobile && !isSearch) {
                <pro-btn icon="search"
                         [tooltip]="'search' | translate"
                         theme="accent"
                         (onClick)="isSearch = true"
                ></pro-btn>
            }

            @if (!isMobile) {
                <pro-btn class="HeaderMenuButton"
                         theme="primary"
                         icon="smile"
                         (onClick)="showEmojis()"
                         [tooltip]="'emojis' | translate"
                         [tooltipPosition]="'right'"
                ></pro-btn>

<!--                <pro-btn class="HeaderMenuButton"-->
<!--                         theme="primary"-->
<!--                         icon="headset"-->
<!--                         (onClick)="goToTalkRoom()"-->
<!--                         [tooltip]="'call_support' | translate"-->
<!--                         [tooltipPosition]="'right'"></pro-btn>-->
            }

            <pro-btn class="HeaderMenuButton"
                    theme="primary"
                    icon="qrcode"
                    (onClick)="listenQr()"
                    [tooltip]="'qrcode' | translate"
                    [tooltipPosition]="'left'"
            ></pro-btn>
            <pro-btn [matMenuTriggerFor]="actionsMenu"
                    class="HeaderMenuButton"
                    theme="primary"
                    icon="bars"
                    [tooltip]="'menu' | translate"
                    [tooltipPosition]="'left'"
            ></pro-btn>
            @if (isMobile && isSearch) {
                <mat-toolbar-row>
                    <pm-search fxFlex></pm-search>
                    <pro-btn icon="times"
                             theme="primary"
                             (onClick)="isSearch = false"
                    ></pro-btn>
                </mat-toolbar-row>
            }
        </mat-toolbar>
    `, changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        .Header {
            height: initial!important;
            /*overflow-x: scroll*/
        }

        .HeaderMenuButton {
            font-size: 16px;
        }

        .HeaderDashboardLink {
            margin: 0 4px;
        }
    `]
})

export class HeaderComponent implements OnInit, OnDestroy {
    watcher: Subscription;
    dashboards: any;
    states: any = {};
    isMobile: boolean;
    isSearch: boolean;
    currUser: CurrUser;
    dashboardEntity: any;
    companyConnection: any;
    hasSmartonDebts: boolean;

    constructor(
        private media: MediaObserver,
        private Location: Location,
        private Prefs: PreferencesService,
        private UiPrefs: UiPreferencesService,
        private Dialog: Dialog,
        private TableCache: TableCacheService,
        private Filter: FilterService,
        private cd: ChangeDetectorRef,
        private store: Store,
        private PromanState: PromanStateService,
        private Entity: Entity,
        private TableHelper: TableHelperService,
        private Observables: ObservablesService,
        private Router: Router,
        public ACL: ACL
    ) {
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);

        this.store.select(getPublicSystemOptions)
          .subscribe((value) => this.hasSmartonDebts = this.hasSmartonDebts = value.hasSmartonDebts && isNumber(parseInt(value.hasSmartonDebts)) && parseInt(value.hasSmartonDebts) !== 0);
        this.watcher = media.asObservable().subscribe((change: MediaChange[]) => {

            if (change.some((change: MediaChange) => change.mqAlias === 'lt-md')) {
                this.isMobile = true;

            } else {
                this.isMobile = false;
                this.isSearch = false;

            }

            this.cd.markForCheck();

        });

        this.dashboardEntity = this.Entity.get('dashboard');
    }

    ngOnInit() {
        this.getDashboards();

        window.addEventListener('keyup', (event) => {
            if ((event.key === 'k' || event.key === 'K') && (event.metaKey || event.ctrlKey)) {
                this.quickSearch();
            }
        });
    }

    ngOnDestroy() {
        this.watcher.unsubscribe();
    }

    callAction(dashboard: any) {
        let config: any;
        let state;
        let stateParams;

        try {
            config = JSON.parse(dashboard.config);
            state = config.state;
        } catch (e) {
            config = {};
        }

        if (dashboard.type === 'filter') {
            stateParams = { filter: dashboard.id };

            this.PromanState.to(state, stateParams);

        } else if (dashboard.type === 'button_action' || dashboard.type === 'item_action') { // item action legacy support
            (this.Entity.get({ name: 'button', post: ['trigger'] }) as any)
                .trigger({ id: config.action });

        } else if (dashboard.type === 'template_preview') {
            (this.Entity.get('template') as any)
                .render({ id: config.template })
                .then((response: any) => {
                    this.Dialog
                        .open(TextPreviewDialogComponent, { type: 'html', header: config.name, text: response, downloadable: true });
                });

        } else if (dashboard.type === 'template_xyz') {
            (this.Entity.get('template') as TemplateEntityInterface)
                .xyzReport({template: config.template})
                .then((response: any) => {
                    if (response) {
                        this.Dialog.open2(ViewPdfDialogComponent, { file: response });

                    }
                })

        } else if (dashboard.type === 'link' ) {
            this.PromanState.to(state);

        } else if (dashboard.type === 'link_filter' ) {
            this.Router.navigate([state], { queryParams: { savedFilter: config.filter } });

        } else if (['link', 'filter'].indexOf(dashboard.type) === -1) {
            state = 'Dashboard';
            stateParams = dashboard.id;

            this.PromanState.to(state, stateParams);
        }

    }

    toggleMenu() {
        this.Observables.layoutToggle();
    }

    getTooltip(dashboard: any) {
        let config;
        let tooltip;

        try {
            config = JSON.parse(dashboard.config);
        } catch (e) {
            config = {};
        }

        if (config && typeof config.name !== 'undefined') {
            tooltip = this.Filter.translate(config.name);

        } else if (dashboard.type === 'filter') {
            tooltip = [(this.Filter.translate(this.states[config.state] || {}).translation), dashboard.id].join(' ');

        } else if (dashboard.type === 'link_filter') {
            const parts = config.state.split('/');

            tooltip = parts.map((part: string) => this.Filter.translate(snakeCase(part))).join(' ');

        } else if (config) {
            tooltip = (this.Filter.translate((this.states[config.state] || {}).translation));

        } else {
            tooltip = dashboard.name;

        }

        return tooltip;
    }

    getIcon(dashboard: any) {
        let config;
        let icon;

        try {
            config = JSON.parse(dashboard.config);
        } catch (e) {
            config = {};
        }

        if (dashboard.icon) {
            icon = dashboard.icon

        } else if (config && typeof config.icon !== 'undefined') {
            icon = config.icon;

        } else if (config) {

            switch (config.state) {

                default:
                    icon = 'asterisk';
            }

        } else {
            icon = 'asterisk';

        }

        if (FaIcons.indexOf(icon) === -1) icon = 'asterisk';

        return icon;
    }

    getDashboards = () => {
        this.dashboardEntity
            .search({
                'owner.id': this.currUser.person.id,
                'sort': { position: 'asc' }
            })
            .then((data: any) => {
                this.dashboards = data;
                this.updateView();
            });
    };

    changeSettings = () => {
        this.Dialog
            .open(DashboardSettingsDialogComponent, { dashboards: this.dashboards })
            .then(() => this.getDashboards());
    };

    addAction = () => {
        this.Dialog
            .open(DashboardAddActionTemplateButtonDialogComponent, { type: 'action' })
            .then(() => this.getDashboards());
    };

    addTemplatePreview = () => {
        this.Dialog
            .open(DashboardAddActionTemplateButtonDialogComponent, { type: 'template' })
            .then(() => this.getDashboards());
    };

    addHyperlink = () => {
        this.Dialog
            .open(DashboardHyperlinkDialogComponent)
            .then(this.getDashboards);
    };

    addAsFilter = (): void => {
        function getKeyHash(): string {
            return Math.random().toString(36).substr(0, 9);
        }

        const currPath = this.Location.path().split('?')[0];
        const keyHash = getKeyHash();
        const tableFilters = this.UiPrefs.get(UI_TABLE_SAVED_FILTERS) || {};

        if (tableFilters[keyHash]) {
            return this.addAsFilter();
        }

        tableFilters[keyHash] = this.TableHelper.filterParams;
        tableFilters[`${keyHash}_cols`] = this.TableHelper.columnsParams;

        this.dashboardEntity
            .create({
                config: JSON.stringify({ state: currPath, filter: keyHash }),
                owner: this.currUser.person.id,
                type: 'link_filter',
                icon: FaIcons[Math.floor(Math.random() * FaIcons.length)],
                position: this.dashboards.length
            })
            .then(() => {
                this.UiPrefs.set(UI_TABLE_SAVED_FILTERS, tableFilters);
                this.TableCache.saveSettings();

                this.getDashboards();
            });
    };

    updateView = () => this.cd.markForCheck();

    listenQr = () => {
        this.Dialog
            .open(UrlReadDialogComponent);
    };

    showEmojis() {
        this.Dialog.open(DashboardEmojiDialogComponent);
    }

    addToMenu() {
        this.Dialog.open(AddUserMenuItemDialogComponent);
    }

    quickSearch() {
        this.Dialog.open(DashboardQuickSearchDialogComponent, null, { width: '400px' });

    }

    goToTalkRoom() {
        this.Router.navigate(['/talk-room']);
    }

    checkAdmin = () => {
        return this.currUser.person?.specialisations?.some((spec: Specialisation) => spec.type === 'administrator')
    }
}
