import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PromanStateService } from '../services/proman-state.service';
import { CurrUser } from '@proman/interfaces/object-interfaces';

@Component({
    selector: 'pm-default-route',
    template: `

    `
})

export class DefaultRouteComponent {
    currUser: CurrUser;

    constructor(
        private Router: Router,
        private PromanState: PromanStateService,
    ) {


        this.PromanState.getAllowedState();
    }

}
