import { debounceTime, filter } from '@proman/rxjs-common';
import { Component } from '@angular/core';
import { TitleService } from '@frontend/shared/services/title.service';
import { Entity } from '@proman/services/entity.service';
import { NavigationEnd, Router } from '@angular/router';
import { LanguageConfigService } from '@proman/services/language-config.service';
import { MenuService } from '@frontend/shared/services/menu.service';
import { Store } from '@ngrx/store';
import { getPendingRequests } from '@proman/store/utils';
import { Observable } from 'rxjs';
import { closeDialog } from '@proman/store/system-options';

@Component({
    selector: 'pm-app',
    template: `
        <div class="App-buffer-container" [ngClass]="{ 'VisibilityHidden': !(isPendingRequests$ | async) }">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
        </div>
        <router-outlet></router-outlet>
    `,
    styles: [`
        .App-buffer-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
            z-index: 2;
        }
    `]
})

export class AppComponent {
    isPendingRequests$: Observable<number>;

    constructor(
        private Entity: Entity,
        public Title: TitleService,
        private Router: Router,
        private Menu: MenuService,
        private LangConfig: LanguageConfigService,
        private store: Store,
    ) {
        this.LangConfig.set();

        this.Router.events.pipe(
            filter((event) => (event instanceof NavigationEnd)))
            .subscribe((val) => {
                this.handleStateChange();
            });

        this.Router.events.subscribe((event: any) => {
            // console.log('navigation event', event);
        });


        this.setCustomScrollbar();

        this.isPendingRequests$ = this.store.select(getPendingRequests)
            .pipe(debounceTime(25));

        if (!window.localStorage.getItem('prefs.language')) {
            window.localStorage.setItem('prefs.language', 'en');
        }
    }

    handleStateChange = () => {
        setTimeout(() => {
            this.Title.setFromState(this.Menu.activeCategoryTab || this.Menu.activeCategory);
        }, 40);
        this.store.dispatch(closeDialog());
    };

    getState(state: any): any {

        if (state.translation) {
            return state;

        } else if (state.parent && state.parent !== 'proman') {
            return this.getState(state.parent);

        } else {
            return state;

        }

    }

    setCustomScrollbar() {
        if (navigator.appVersion.indexOf('Mac') === -1) { document.querySelector('body').classList.add('CustomScroll'); }
    }

}
