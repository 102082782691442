import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Fa6Module } from '@proman/fa/fa6.module';
import { PipesModule } from '../shared/pipes/pipes.module';
import { GlobalOverlayModule } from '../overlay/global-overlay.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { SharedDirectivesModule } from '@proman/shared/directives/shared-directives.module';
import { InlineListComponent } from "@proman/inline-list/inline-list.component";
import { InlineListService } from "@proman/inline-list/inline-list.service";
import { PromanTextSimpleComponent } from "@proman/text-simple/proman-text-simple.component";

const COMPONENTS = [
  InlineListComponent
]

@NgModule({
  imports: [
    CommonModule,
    Fa6Module,
    PipesModule,
    GlobalOverlayModule,
    FlexLayoutModule,
    SharedDirectivesModule,
    PromanTextSimpleComponent,
  ],
  providers: [
    InlineListService,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})

export class InlineListModule {

}
