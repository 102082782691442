import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Action } from '@proman/interfaces/object-interfaces';
import { OnDropEmitType } from '@proman/list-manager/list-manager.component';
import { getIndexByProperty, moveByProperty } from '@proman/utils';

@Component({
  selector: 'pm-toolbar-actions-dialog',
  template: `
        <pro-dialog-title title="edit"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
              <pro-list-manager [items]="actions"
                               [config]="{ template: 'toobar_action_template', noAdd: true }"
                               (onDrop)="handleDrop($event)"
              ></pro-list-manager>
        </div>
        <pro-dialog-actions>
          <pro-btn [label]="'save'"
                   [theme]="'accent'"
                   (onClick)="confirm()"></pro-btn>
        </pro-dialog-actions>
    `
})

export class ToolbarActionsDialogComponent {
  actions: Action[];

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { actions: Action[] },
    private dialogRef: MatLegacyDialogRef<ToolbarActionsDialogComponent>,
  ) {
    this.actions = [...this.data.actions];
  }

  setPositions() {
    this.actions = this.actions.map((a, i) => ({ ...a, position: i }));
  }

  handleDrop(data: OnDropEmitType<Action>) {
    const oldIndex = getIndexByProperty<Action>(this.actions, 'icon', data.item.icon);
    this.actions = moveByProperty<Action>(this.actions, oldIndex, data.position);
  }

  confirm() {
    this.dialogRef.close(this.actions);
  }

}
