import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@proman/services/toast.service';
import { copyToClipboard } from '@proman/utils';

@Component({
  selector: 'pm-dashboard-emoji',
  template: `
    <pro-dialog-title title="emoji"></pro-dialog-title>
    <div mat-dialog-content fxLayout="column" pmPadding>
      <div fxLayout="row wrap" fxFlex>
        <pro-btn *ngFor="let _type of types"
                 [label]="_type"
                 [theme]="_type === type ? 'accent' : 'primary'"
                 (onClick)="setType(_type)"></pro-btn>
      </div>

      <div pmPadding [ngStyle]="{ 'font-size': type ? '50px': '28px' }">
        <div>
          <ng-container *ngIf="canShow('smileys_&_people')">
            <pro-btn *ngFor="let smileysAndPeopleEmoji of this.smileysAndPeopleEmojiArr" class="emojiButton"
                     [label]="smileysAndPeopleEmoji"
                     (onClick)="copy(smileysAndPeopleEmoji)"
            >
            </pro-btn>
          </ng-container>
          <ng-container *ngIf="canShow('food_&_drink')">
            <pro-btn *ngFor="let foodAndDrinkEmoji of this.foodAndDrinkEmojiArr" class="emojiButton"
                     [label]="foodAndDrinkEmoji"
                     (onClick)="copy(foodAndDrinkEmoji)"
            >
            </pro-btn>
          </ng-container>
          <ng-container *ngIf="canShow('animals_&_nature')">
            <pro-btn *ngFor="let animalsAndNatureEmoji of this.animalsAndNatureEmojiArr" class="emojiButton"
                     [label]="animalsAndNatureEmoji"
                     (onClick)="copy(animalsAndNatureEmoji)"
            >
            </pro-btn>
          </ng-container>
          <ng-container *ngIf="canShow('objects')">
            <pro-btn *ngFor="let objectsEmoji of this.objectsEmojiArr" class="emojiButton"
                     [label]="objectsEmoji"
                     (onClick)="copy(objectsEmoji)"
            >
            </pro-btn>
          </ng-container>
          <ng-container *ngIf="canShow('office')">
            <pro-btn *ngFor="let officeEmoji of this.officeEmojiArr" class="emojiButton"
                     [label]="officeEmoji"
                     (onClick)="copy(officeEmoji)"
            >
            </pro-btn>
          </ng-container>
          <ng-container *ngIf="canShow('activity')">
            <pro-btn *ngFor="let activityEmoji of this.activityEmojiArr" class="emojiButton"
                     [label]="activityEmoji"
                     (onClick)="copy(activityEmoji)"
            >
            </pro-btn>
          </ng-container>
          <ng-container *ngIf="canShow('symbols')">
            <pro-btn *ngFor="let symbolsEmoji of this.symbolsEmojiArr" class="emojiButton"
                     [label]="symbolsEmoji"
                     (onClick)="copy(symbolsEmoji)"
            >
            </pro-btn>
          </ng-container>
          <ng-container *ngIf="canShow('flags')">
            <pro-btn *ngFor="let flagsEmoji of this.flagsEmojiArr" class="emojiButton"
                     [label]="flagsEmoji"
                     (onClick)="copy(flagsEmoji)"
            >
            </pro-btn>
          </ng-container>
        </div>

      </div>

    </div>
    <pro-dialog-actions></pro-dialog-actions>
  `
})

export class DashboardEmojiDialogComponent implements OnInit, OnDestroy {
  types: string[] = [
    'smileys_&_people',
    'symbols',
    'food_&_drink',
    'activity',
    'office',
    'animals_&_nature',
    'objects',
    'flags',
  ];

  type: string;
  smileysAndPeopleEmojiArr: string[] = ['😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇', '🙂', '🙃', '😉', '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪', '🤨', '🧐', '🤓', '😎', '🤩', '🥳', '😏', '😒', '😞', '😔', '😟', '😕', '🙁', '😣', '😖', '😫'
    , '😩', '🥺', '😢', '😭', '😤', '😠', '😡', '🤬', '🤯', '😳', '🥵', '🥶', '😱', '😨', '😰', '😥', '😓', '🤗', '🤔', '🤭', '🤫', '🤥', '😶', '😐', '😑', '😬', '🙄', '😯', '😦', '😧', '😮', '😲', '🥱', '😴', '🤤', '😪', '😵', '🤐', '🥴', '🤢', '🤮', '🤧', '😷', '🤒'
    , '🤕', '🤑', '🤠', '😈', '👿', '👹', '👺', '🤡', '💩', '👻', '💀', '☠️', '👽', '👾', '🤖', '🎃', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '😿', '😾', '👋', '🤚', '🖐', '✋', '🖖', '👌', '🤏', '✌️', '🤞', '🤟', '🤘', '🤙', '👈', '👉', '👆', '🖕', '👇', '☝️', '👍'
    , '👎', '✊', '👊', '🤛', '🤜', '👏', '🙌', '👐', '🤲', '🤝', '🙏', '✍️', '💅', '🤳', '💪', '🦾', '🦵', '🦿', '🦶', '👂', '🦻', '👃', '🧠', '🦷', '👀', '👁', '👅', '👄', '🧵', '🧶', '👓', '🕶', '🥽', '🥼', '🦺', '👔', '👕', '👖', '🧣', '🧤', '🧥', '🧦', '👗'
    , '👘', '🥻', '🩱', '🩲', '🩳', '👙', '👚', '👛', '👜', '👝', '🎒', '👞', '👟', '🥾', '🥿', '👠', '👡', '🩰', '👢', '👑', '👒', '🎩', '🎓', '🧢', '⛑', '💄', '💍', '💼', '🎊', '🎉', '💋']

  foodAndDrinkEmojiArr: string[] = ['🍏', '🍎', '🍐', '🍊', '🍋', ' 🍌', '🍉', '🍇', '🍓', '🍈', '🍒', '🍑', '🥭', '🍍', '🥥', '🥝', '🍅', '🍆', '🥑', '🥦', '🥬', '🥒', '🌶', '🌽', '🥕', '🧄', '🧅', '🥔', '🍠', '🥐', '🥯', '🍞', '🥖', '🥨', '🧀', '🥚', '🍳', '🧈', '🥞', '🧇', '🥓', '🥩', '🍗',
    '🍖', '🦴', '🌭', '🍔', '🍟', '🍕', '🥪', '🥙', '🧆', '🌮', '🌯', '🥗', '🥘', '🥫', '🍝', '🍜', '🍲', '🍛', '🍣', '🍱', '🥟', '🦪', '🍤', '🍙', '🍚', '🍘', '🍥', '🥠', '🥮', '🍢', '🍡', '🍧', '🍨', '🍦', '🥧', '🧁', '🍰', '🎂', '🍮', '🍭', '🍬', '🍫', '🍿'
    , '🍩', '🍪', '🌰', '🥜']

  animalsAndNatureEmojiArr: string[] = ['🐶', ' 🐱', ' 🐭', ' 🐹', ' 🐰', ' 🦊 ', '🐻 ', '🐼 ', '🐨 ', '🐯 ', '🦁 ', '🐮 ', '🐷 ', '🐽 ', '🐸 ', '🐵 ', '🙈 ', '🙉 ', '🙊 ', '🐒 ', '🐔 ', '🐧 ', '🐦 ', '🐤', ' 🐣', ' 🐥', ' 🦆', ' 🦅', ' 🦉', ' 🦇', ' 🐺', ' 🐗', ' 🐴', ' 🦄', ' 🐝', ' 🐛', ' 🦋', ' 🐌', ' 🐞', ' 🐜', ' 🦟', ' 🦗', ' 🕷', '🕸', ' 🦂', ' 🐢', ' 🐍', ' 🦎', ' 🦖', ' 🦕', ' 🐙', ' 🦑', ' 🦐', ' 🦞', ' 🦀', ' 🐡', ' 🐠', ' 🐟', ' 🐬', ' 🐳', ' 🐋', ' 🦈', ' 🐊', ' 🐅', ' 🐆', ' 🦓', ' 🦍', ' 🦧', ' 🐘', ' 🦛', ' 🦏', ' 🐪', ' 🐫', ' 🦒', ' 🦘', ' 🐃', ' 🐂', ' 🐄', ' 🐎', ' 🐖', ' 🐏', ' 🐑', ' 🦙', ' 🐐', ' 🦌', ' 🐕', '🐩', ' 🦮', ' 🐈', ' 🐓', ' 🦃', ' 🦚', ' 🦜', ' 🦢', ' 🦩', ' 🕊', ' 🐇', ' 🦝', ' 🦨', ' 🦡', ' 🦦', ' 🦥', ' 🐁', ' 🐀', ' 🐿', ' 🦔', ' 🐾', ' 🐉', ' 🐲', ' 🧸', ' 🌞', ' 🌝', ' 🌛', ' 🌜', ' 🌚', ' 🌕 ', '🌖 ', '🌗', ' 🌘', ' 🌑', ' 🌒', ' 🌓', ' 🌔', ' 🌙', ' 🌎', ' 🌍', ' 🌏', ' 🪐', ' 💫', '⭐', '️ 🌟', ' ✨', ' ⚡', '️ ', '☄️', ' 💥', ' 🔥', ' 🌪', ' 🌈', '️ 🌤', ' ⛅️', ' 🌥', '️ 🌦', ' 🌧', ' ⛈', ' 🌩', ' 🌨', '❄', '️ ⛄', '️ 🌬', ' 💨', ' 💧', ' 💦', '️ 🌊', ' 🌵', ' 🎄', ' 🌲', ' 🌳', ' 🌴', ' 🌱', ' 🌿', ' ☘', '️ 🍀', ' 🎍 ', '🎋 ', '🍃 ', '🍂 ', '🍁', '🍄 ', '🐚 ', '🌾 ', '💐 ', '🌷 ', '🌹 ', '🥀 ', '🌺 ', '🌸 ', '🌼 ', '🌻']

  objectsEmojiArr: string[] = ['🎬', '🎤', '🎧', '🎼', '🎹', '🥁', '🎷', '🎺', '🎸', '🪕', '🎻', '🎲', '🎯', '🎳', '🎮', '🎰', '🧩', '🍯', '🥛', '🍼', '🍵', '🧃', '🥤', '🍶', '🍺', '🍻', '🥂', '🍷', '🥃', '🍸', '🍹', '🧉', '🍾', '🧊', '🥄', '🍴', '🍽', '🥣', '🥡', '🥢', '🧂', '🔧', '🔨', '🛠', '⛏', '🔩', '🧱', '⛓', '🧲', '🔫', '💣', '🧨', '🪓', '🔪', '🗡', '🛡', '🚬', '🏺', '🔮', '📿', '🧿', '💈', '🔭', '🔬', '🕳', '🩹', '🩺', '💊', '💉', '🩸', '🧬', '🦠', '🧫', '🧪', '🌡', '🧹', '🧺', '🧻', '🚽', '🚰', '🚿', '🛁', '🛀', '🧼', '🪒', '🧽', '🧴', '🛎', '🔑', '🗝', '🔏', '🔐', '🔒', '🔓', '🧰', '🚪', '🪑', '🛋', '🛏', '🛌', '🖼', '🛍', '🛒', '🎁', '🧳', '🌂', '🩸', '🎈', '🎏', '🎀', '🎎', '🏮', '🎐', '🧧']

  officeEmojiArr: string[] = ['⌚', '📱', '📲', '💻', '🖨', '🖱', '🖲', '🕹', '🗜', '💽', '💾', '💿', '📀', '📼', '📷', '📸', '📹', '🎥', '📽', '🎞', '📞', '📟', '📠', '📺', '📻', '🎙', '🎚', '🎛', '🧭', '⏱', '⏲', '⏰', '🕰', '⌛', '⏳', '📡', '🔋', '🔌', '💡', '🔦', '🕯', '🪔', '🛢', '💸', '💵', '💴', '💶', '💷', '💰', '💳', '💎', '📩', '📨', '📧', '💌', '📥', '📤', '📦', '🏷', '📪', '📫', '📬', '📭', '📮', '📯', '📜', '📃', '📄', '📑', '🧾', '📊', '📈', '📉', '🗒', '🗓', '📆', '📅', '🗑', '📇', '🗃', '🗳', '🗄', '📋', '📁', '📂', '🗂', '🗞', '📰', '📓', '📔', '📒', '📕', '📗', '📘', '📙', '📚', '📖', '🔖', '🧷', '🔗', '📎', '🖇', '📐', '📏', '🧮', '📌', '📍', '🖊', '🖋', '🖌', '🖍', '📝', '🔍', '🔎']

  activityEmojiArr: string[] = ['⛷', '🏂', '🪂', '🏋', '🏆', '🥇', '🥈', '🥉', '🏅', '🎖', '🏵', '🎗', '🎫', '🎟', '🎪', '🤹', '🎭', '🩰', '🎨', '⚽', '🏀', '🏈', '⚾', '🥎', '🎾', '🏐', '🏉', '🥏', '🎱', '🪀', '🏓', '🏸', '🏒', '🏑', '🥍', '🏏', '🥅', '⛳', '🪁', '🏹', '🎣', '🤿', '🥊', '🥋', '🎽', '🛹', '🛷', '⛸', '🥌', '🎿']

  symbolsEmojiArr: string[] = ['❤️', '🧡', '💛', '💚', '💙', '💜', '🖤', '🤍', '🤎', '🔴', '🟠', '🟡', '🟢', '🔵', '🟣', '⚫', '⚪', '🟤', '🔺', '🔻', '🔸', '🔹', '🔶', '🔷', '🔳', '🔲', '▪️', '  ▫️', '🟥', '🟧', '🟨', '🟩', '🟦', '🟪', '⬛', '⬜', '🟫', '🏳', '🏴', '🏁', '🚩', '🏳', '🌈', '🏴', '☠']


  flagsEmojiArr: string[] = ['🇦🇫', '🇦🇽', '🇦🇱', '🇩🇿', '🇦🇸', '🇦🇩', '🇦🇴', '🇦🇮', '🇦🇶', '🇦🇬', '🇦🇷', '🇦🇲',
    '🇦🇼', '🇦🇺', '🇦🇹', '🇦🇿', '🇧🇸', '🇧🇭', '🇧🇩', '🇧🇧', '🇧🇾', '🇧🇪', '🇧🇿', '🇧🇯', '🇧🇲', '🇧🇹', '🇧🇴', '🇧🇦', '🇧🇼', '🇧🇻', '🇧🇷', '🇮🇴', '🇧🇳', '🇧🇬', '🇧🇫', '🇧🇮', '🇰🇭', '🇨🇲', '🇨🇦', '🇨🇻', '🇧🇶', '🇰🇾', '🇨🇫',
    '🇹🇩', '🇨🇱', '🇨🇳', '🇨🇽', '🇨🇨', '🇨🇴', '🇰🇲', '🇨🇬', '🇨🇩', '🇨🇰', '🇨🇷', '🇨🇮', '🇭🇷', '🇨🇺', '🇨🇼', '🇨🇾', '🇨🇿', '🇩🇰', '🇩🇯', '🇩🇲', '🇩🇴', '🇪🇨', '🇪🇬', '🇸🇻', '🏴󠁧󠁢󠁥󠁮󠁧󠁿', '🇬🇶', '🇪🇷', '🇪🇪', '🇸🇿', '🇪🇹', '🇫🇰',
    '🇫🇴', '🇫🇯', '🇫🇮', '🇫🇷', '🇬🇫', '🇵🇫', '🇹🇫', '🇬🇦', '🇬🇲', '🇬🇪', '🇩🇪', '🇬🇭', '🇬🇮', '🇬🇷', '🇬🇱', '🇬🇩', '🇬🇵', '🇬🇺', '🇬🇹', '🇬🇬', '🇬🇳', '🇬🇼', '🇬🇾', '🇭🇹', '🇭🇲', '🇭🇳', '🇭🇰', '🇭🇺', '🇮🇸', '🇮🇳', '🇮🇩',
    '🇮🇷', '🇮🇶', '🇮🇪', '🇮🇲', '🇮🇱', '🇮🇹', '🇯🇲', '🇯🇵', '🇯🇪', '🇯🇴', '🇰🇿', '🇰🇪', '🇰🇮', '🇰🇵', '🇰🇷', '🇽🇰', '🇰🇼', '🇰🇬', '🇱🇦', '🇱🇻', '🇱🇧', '🇱🇸', '🇱🇷', '🇱🇾', '🇱🇮', '🇱🇹', '🇱🇺', '🇲🇴', '🇲🇬', '🇲🇼', '🇲🇾',
    '🇲🇻', '🇲🇱', '🇲🇹', '🇲🇭', '🇲🇶', '🇲🇷', '🇲🇺', '🇾🇹', '🇲🇽', '🇫🇲', '🇲🇩', '🇲🇨', '🇲🇳', '🇲🇪', '🇲🇸', '🇲🇦', '🇲🇿', '🇲🇲', '🇳🇦', '🇳🇷', '🇳🇵', '🇳🇱', '🇳🇨', '🇳🇿', '🇳🇮', '🇳🇪', '🇳🇬', '🇳🇺', '🇳🇫', '🇲🇰', '🇲🇵',
    '🇳🇴', '🇴🇲', '🇵🇰', '🇵🇼', '🇵🇸', '🇵🇦', '🇵🇬', '🇵🇾', '🇵🇪', '🇵🇭', '🇵🇳', '🇵🇱', '🇵🇹', '🇵🇷', '🇶🇦', '🇷🇪', '🇷🇴', '🇷🇺', '🇷🇼', '🇧🇱', '🇸🇭', '🇰🇳', '🇱🇨', '🇲🇫', '🇵🇲', '🇻🇨', '🇼🇸', '🇸🇲', '🇸🇹', '🇸🇦', '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    '🇸🇳', '🇷🇸', '🇸🇨', '🇸🇱', '🇸🇬', '🇸🇽', '🇸🇰', '🇸🇮', '🇸🇧', '🇸🇴', '🇿🇦', '🇬🇸', '🇸🇸', '🇪🇸', '🇱🇰', '🇸🇩', '🇸🇷', '🇸🇯', '🇸🇪', '🇨🇭', '🇸🇾', '🇹🇼', '🇹🇯', '🇹🇿', '🇹🇭', '🇹🇱', '🇹🇬', '🇹🇰', '🇹🇴', '🇹🇹', '🇹🇳',
    '🇹🇷', '🇹🇲', '🇹🇨', '🇹🇻', '🇺🇬', '🇺🇦', '🇦🇪', '🇬🇧', '🇺🇲', '🇺🇾', '🇺🇿', '🇻🇺', '🇻🇦', '🇻🇪', '🇻🇳', '🇻🇬', '🇻🇮', '🏴󠁧󠁢󠁷󠁬󠁳󠁿', '🇼🇫', '🇪🇭', '🇾🇪', '🇿🇲', '🇿🇼']

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private Toast: ToastService
  ) {
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }


  canShow(type: string) {
    return this.type === type || !this.type;
  }

  setType(type: string) {
    this.type = (type === this.type) ? '' : type;
  }

  copy(emoji: string) {
    copyToClipboard(emoji)
    this.Toast.pop('info', `${emoji} copied_to_clipboard`);
  }

}
