import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity, EntityInterface } from '@proman/services/entity.service';
import { MenuService } from '@frontend/shared/services/menu.service';
import { routeMap } from '@frontend/shared/proman-route-map';
import { FaIcons } from '../../core/icons';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';

@Component({
    selector: 'pm-dashboard-hyperlink',
    template: `
        <pro-dialog-title title="click_you_want_to_add_menu_item"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <mat-list>
                <div *ngFor="let item of Menu.items">
                    <mat-list-item (click)="handleClick(item)"
                                   class="Clickable">
                        <div class="mat-list-item-text RightPadding">
                            <p>{{ item.name | translate }}</p>
                        </div>
                        <fa *ngIf="item.tabs.length"
                               name="angle-down"
                               class="Rotate"
                               [ngClass]="{ 'is-rotated': item.isExpanded }"
                               (click)="handleClick(item)"
                               proClickStopPropagation></fa>

                    </mat-list-item>
                    <div fxLayout="column"
                         class="ChildContainer"
                         *ngIf="item.isExpanded">
                        <mat-list>
                            <mat-list-item *ngFor="let tab of item.tabs"
                                           (click)="handle(tab, item)"
                                           class="Clickable">
                                {{ tab.name | translate }}
                            </mat-list-item>
                        </mat-list>
                    </div>
                    <hr>
                </div>
            </mat-list>
        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `,
    styles: ['.ChildContainer { padding-left: 16px; }']
})

export class DashboardHyperlinkDialogComponent {
    dashboardEntity: EntityInterface;
    currUser: CurrUser;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private store: Store,
        public Menu: MenuService,
        private Entity: Entity,
        private dialogRef: MatLegacyDialogRef<DashboardHyperlinkDialogComponent>,
    ) {
        this.dashboardEntity = this.Entity.get('dashboard');
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
    }

    handle(item: any, parent: any) {
        (parent) ?
            this.add(item.state, parent.icon, item.name) :
            this.handleClick(item);

    };

    handleClick(item: any) {
        (item.tabs && item.tabs.length) ?
            item.isExpanded = !item.isExpanded :
            this.add(item.state, null, null);

    }

    add(state: any, icon: string, name: string) {
        this.dashboardEntity
            .create({
                config: JSON.stringify({
                    state: routeMap[state],
                    name
                }),
                icon: FaIcons[Math.floor(Math.random() * FaIcons.length)],
                owner: this.currUser.person.id,
                type: 'link'
            })
            .then(() => this.dialogRef.close());
    }

}
