import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardSettingsDialogComponent } from './components/dashboard-settings-dialog.component';
import { AddWidgetDialogComponent } from './components/add-widget-dialog.component';
import { ChangeDashboardIconDialogComponent } from './components/change-dashboard-icon-dialog.component';
import { DashboardHyperlinkDialogComponent } from './components/dashboard-hyperlink-dialog.component';
import { DashboardAddActionTemplateButtonDialogComponent } from './components/dashboard-add-action-template-button-dialog.component';
import { PromanCommonComponentsModule } from '@proman/common-components/proman-common-components.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { DragulaModule } from 'ng2-dragula';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { InputsModule } from '../inputs/inputs.module';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { DashboardEmojiDialogComponent } from './components/dashboard-emoji-dialog.component';
import { SharedDirectivesModule } from "@proman/shared/directives/shared-directives.module";
import { FrontendDirectivesModule } from "@frontend/shared/frontend-directives.module";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { Fa6Module } from "@proman/fa/fa6.module";
import { DashboardQuickSearchDialogComponent } from './components/dashboard-quick-search-dialog.component';
import { FrontendPipesModule } from '@frontend/shared/pipes/frontend-pipes.module';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        DragulaModule.forRoot(),
        MatExpansionModule,
        MatLegacyListModule,
        MatLegacyDialogModule,
        InputsModule,
        PipesModule,
        FlexLayoutModule,
        SharedComponentsModule,
        PromanCommonComponentsModule,
        SharedDirectivesModule,
        FrontendDirectivesModule,
        Fa6Module,
        FrontendPipesModule,
        RouterModule,
    ],
    declarations: [
        DashboardSettingsDialogComponent,
        AddWidgetDialogComponent,
        ChangeDashboardIconDialogComponent,
        DashboardHyperlinkDialogComponent,
        DashboardAddActionTemplateButtonDialogComponent,
        DashboardEmojiDialogComponent,
        DashboardQuickSearchDialogComponent,
    ]
})
export class DashboardsModule { }
