import { Injectable } from '@angular/core';
import { MenuService } from './menu.service';
import { FilterService } from '@proman/services/filter.service';
import { Location } from '@angular/common';
import { CONFIG } from '@proman/config';
import { Store } from '@ngrx/store';
import { SystemOptions } from '@proman/interfaces/entity-interfaces';
import { getSystemOptions } from '@proman/store/system-options';

const MAIN_TITLE = 'PROMAN';

/**
 * `Title` service
 *
 */

@Injectable({ providedIn: 'root' })
export class TitleService {
    systemOptions: SystemOptions;

    constructor(
        private Menu: MenuService,
        private Filter: FilterService,
        private Location: Location,
        private store: Store,
    ) {
        this.store.select(getSystemOptions)
            .subscribe((value) => this.systemOptions = value);
    }

    updateDocument(name: any, path: any) {
        let title = name;
        let url = this.Location.path();
        let parts = url.split('/');
        let id;

        for (let part of parts) {
            if (!isNaN(parseInt(part))) {
                id = parseInt(part);
                break;

            }
        }

        if (path) {
            if (id) path += ` #${id}`;

            title = `${path} | ${name}`;
        }

        window.document.title = title || MAIN_TITLE;
    };

    formatPath(category: any, categoryItem: any) {
        let output = '';

        if (categoryItem) {
            output = this.Filter.translate(categoryItem.name);

        } else if (category) {
            output = this.Filter.translate(category.name);

        }

        return output;
    };

    getPath(state: any) {
        let title = '';
        let menuCategories = this.Menu.items;
        let menuCategory;
        let menuCategoryItem;
        let cat;
        let catTab;
        let isFound;

        if (menuCategories) {

            for (menuCategory of menuCategories) {

                if (menuCategory.tabs) {

                    for (menuCategoryItem of menuCategory.tabs) {

                        if (menuCategoryItem.state === state) {
                            cat = menuCategory;
                            catTab = menuCategoryItem;
                            isFound = true;

                            break;
                        }

                    }

                } else {

                    if (menuCategory.state === state) {
                        cat = menuCategory;
                        isFound = true;

                        break;
                    }

                }

                if (isFound) break;

            }

            title = this.formatPath(cat, catTab);
        }

        return title;
    };

    setFromState(state: string) {
        let path = this.getPath(state);
        let systemOptions = (this.systemOptions || { mainPortalTitle: '' });
        let name = systemOptions && systemOptions.mainPortalTitle || MAIN_TITLE;

        if (window.location.pathname.includes('test-')) name = `TEST -  ${name}`;
        if (CONFIG.root.includes('test-')) name = `TEST -  ${name}`;

        this.updateDocument(name, path);
    };
}
