import { distinctUntilChanged } from '@proman/rxjs-common';
import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { OverlayService } from '@frontend/shared/services/overlay.service';

import { debounce } from '@proman/utils';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getDocsIdData } from '@proman/store/docs-id/docs-id.selectors';
import { FilterService } from '@proman/services/filter.service';
import { DocsIdValues } from '@proman/store/docs-id/docs-id.reducer';

@Directive({
    selector: '[docsId]'
})
export class DocsIdDirective implements OnInit, OnDestroy {
    @Input('docsId') key: string|null;

    visible: boolean = false;
    toBeShown: boolean = false;

    debounceShow: any;
    debounceHide: any;

    _docsActive: Subscription;
    isDocsActive: boolean;
    docsIdData: DocsIdValues;

    constructor(
        private Overlay: OverlayService,
        private Filter: FilterService,
        private store: Store
    ) {

    }

    @HostListener('mouseenter', ['$event'])
    public setOverlayData($event: MouseEvent) {
        if (!this.docsIdData) return;
        this.debounceShow($event);

        this.toBeShown = true;

    }

    @HostListener('mouseleave')
    public disableOverlay() {
        if (!this.docsIdData) return;

        if (!this.visible) {
            this.debounceShow.cancel();

        } else {
            this.debounceHide();

            this.visible = false;

        }

        this.toBeShown = true;
    }

    ngOnInit() {

        this._docsActive = this.Overlay.docActive.pipe(
            distinctUntilChanged())
            .subscribe((value: any) => {
                this.isDocsActive = value;
            });

        this.store
            .select(getDocsIdData)
            .subscribe((data) => {
                this.docsIdData = data[this.Filter.translate(this.key)];
            });

        this.debounceShow = debounce(($event: MouseEvent) => {
            this.Overlay.docsSetKey(this.docsIdData);
            this.Overlay.docsShow($event);

            this.visible = true;

        }, 850);

        this.debounceHide = debounce(() => {
            if (!this.isDocsActive) this.Overlay.docsHide();

        }, 400);

    }

    ngOnDestroy() {
        if (this.toBeShown) this.debounceShow.cancel();
        if (this.visible) this.Overlay.docsHide();

    }

}
