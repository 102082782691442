import { Injectable } from '@angular/core';
import { date } from '@proman/interfaces/common.interface';
import moment from 'moment';

export interface Warning {
    date: date;
    message: string;
}

@Injectable({ providedIn: 'root' })
export class WarningsService {
    data: Warning[] = [];

    constructor(

    ) {

    }

    push(message: string) {
        const date = moment().format();

        this.data.push({ message, date });
    }

    getWarnings() {
        return this.data;
    }

}
