import { ModuleWithProviders, NgModule } from '@angular/core';
import { GuardsAclService } from './guards-acl.service';
import { PromanModule } from '@proman/proman.module';
import { GuardsEmployeeService } from '@frontend/guards/guards-employee.service';
import { GuardsSelfAuthorizationService } from '@frontend/guards/guards-self-authorization.service';

const PROVIDERS = [
    GuardsAclService,
    GuardsEmployeeService,
    GuardsSelfAuthorizationService,
];

@NgModule({
    imports: [PromanModule],
    providers: PROVIDERS
})
export class GuardsModule {
    static forRoot(): ModuleWithProviders<GuardsModule> {
        return {
            ngModule: GuardsModule,
            providers: PROVIDERS
        };
    }
}
