import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer as parametersReducer, ParametersEffects, parametersKey } from './cached-requests/store';
@NgModule({
    imports: [
        StoreModule.forFeature(parametersKey, parametersReducer),
        EffectsModule.forFeature([ParametersEffects]),
    ]
})

export class ParametersStoreModule {}
