import { filter } from '@proman/rxjs-common';
import {
    Component,
    OnDestroy,
    OnInit,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    SimpleChanges, OnChanges, AfterViewInit, Inject
} from '@angular/core';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { MenuService } from '../services/menu.service';
import { debounce, delay } from '@proman/utils';
import { PromanStateService } from '../services/proman-state.service';
import { NavigationEnd, Router } from '@angular/router';
import { UI_COMPACT_MENU } from '@proman/services/ui-preferences.service';
import { Store } from '@ngrx/store';
import { getCurrUserUiPrefs, getMinimalMenu, loadMinimalMenu } from '@proman/store/curr-user';
import { UserMenu, UserMenuGroup } from '@proman/interfaces/entity-interfaces';
import { EditUserMenuItemDialogComponent } from '../../shared-dialogs/components/edit-user-menu-item-dialog.component';
import { Dialog } from '../services/dialog.service';

@Component({
    selector: 'pm-menu',
    template: `
        @switch (useStandardMenu) {
            @case (true) {
                <div fxLayout="column">
                    @for (category of menu; track $index) {
                        <div class="Menu"
                             fxLayout="column"
                             (mouseleave)="handleMouseLeave()">
                            <a class="Menu-category"
                               [ngClass]="{ 'active': checkActiveCategory(category) }"
                               mat-button
                               fxLayout="row"
                               fxLayoutAlign="start center"
                               (click)="selectCategory(category)">
                                @if (category.icon) {
                                    <fa class="Category-icon" [custom]="'fw'" [name]="category.icon"></fa>
                                }
                                <span class="Menu-category--name"
                                      [attr.data-menu]="category.name">{{ category.name | translate }}</span>
                            </a>
                            @if (isVisibleItems(category) && ((!isCompactMenu && category.tabs) || (isCompactMenu && previewCategory))) {
                                <div class="Menu-tabs"
                                     fxLayout="column"
                                     [ngClass]="{ 'Menu-tabs--open': category.tabs && isVisibleItems(category) }"
                                     (mouseenter)="handleMouseEnter()"
                                     [@shrinkOut]>
                                    @for (tab of category.tabs; track $index) {
                                        @if (isVisibleTab(category, tab)) {
                                            <a class="Menu-tabs--tab"
                                               [ngClass]="{ 'active': tab.state === activeCategoryTab }"
                                               [attr.data-submenu]="tab.name"
                                               mat-button
                                               [docsId]="tab.name"
                                               [routerLink]="tab.state | pmSref">{{ tab.name | translate }}</a>
                                        }
                                    }
                                </div>
                            }
                        </div>
                    }

                    @if (unassigned?.length || grouped?.length) {
                        <ng-container *ngTemplateOutlet="standardMenuButton"></ng-container>
                    }
                </div>
            }
            @case (false) {
                <div fxLayout="column">
                    @for (menu of grouped; track $index) {
                        <div class="Menu"
                             fxLayout="column"
                             (mouseleave)="handleMouseLeave()">
                            <a class="Menu-category"
                               [ngClass]="{ 'active': checkActiveUserMenuCategory(menu) }"
                               mat-button
                               fxLayout="row"
                               fxLayoutAlign="start center"
                               (click)="selectUserMenuCategory(menu)">
                                @if (menu.icon) {
                                    <fa class="Category-icon" [custom]="'fw'" [name]="menu.icon"></fa>
                                }
                                <span class="Menu-category--name"
                                      [attr.data-menu]="menu.name">{{ menu.name | translate }}</span>
                            </a>

                            @if (isVisibleItems(menu) && ((!isCompactMenu && menu.items) || (isCompactMenu && previewCategory))) {
                                <div class="Menu-tabs"
                                     fxLayout="column"
                                     [ngClass]="{ 'Menu-tabs--open': menu.items && isVisibleItems(menu), 'active': checkActiveUserMenuCategory(menu) }"
                                     (mouseenter)="handleMouseEnter()"
                                     [@shrinkOut]>
                                    @for (item of menu.items; track $index) {
                                        @if (isVisibleTab(menu, item)) {
                                            <a class="Menu-tabs--tab"
                                               [ngClass]="{ 'active': item.state === activeCategoryTab }"
                                               [attr.data-submenu]="item.name"
                                               mat-button
                                               (click)="handleUserMenu(item)">{{ item.name | translate }}</a>
                                        }
                                    }
                                </div>
                            }
                        </div>
                    }

                    @for (menu of unassigned; track $index) {
                        <div class="Menu"
                             fxLayout="column"
                             (mouseleave)="handleMouseLeave()">
                            <a class="Menu-category"
                               mat-button
                               fxLayout="row"
                               fxLayoutAlign="start center"
                               (click)="handleUserMenu(menu)">
                                @if (menu.icon) {
                                    <fa class="Category-icon" [name]="menu.icon"></fa>
                                }
                                <span class="Menu-category--name"
                                      [attr.data-menu]="menu.name">{{ menu.name | translate }}</span>
                            </a>
                        </div>
                    }
                    <ng-container *ngTemplateOutlet="standardMenuButton"></ng-container>
                </div>
            }
        }

        <ng-template #standardMenuButton>
            <div fxFlex>&nbsp;</div>
            <div class="Menu"
                 fxLayout="column"
                 (mouseleave)="handleMouseLeave()">
                <a class="Menu-category"
                   mat-button
                   fxLayout="row"
                   fxLayoutAlign="center center">
                        <span class="Menu-category--name"
                              [attr.data-menu]="'stardard_menu'">
                             <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" fxFlex>
                                <fa class="Category-icon"
                                    [name]="'exchange'"
                                    size="1x"
                                    (click)="toggleStandartMenu()"></fa>
                                 @if (!useStandardMenu) {
                                     <fa class="Category-icon" [name]="'edit'"
                                         size="1x"
                                         (click)="editMinimalMenu()"></fa>
                                 }
                             </div>
                        </span>
                </a>
            </div>
        </ng-template>
    `,
    animations: [
        trigger('shrinkOut', [
            state('in', style({ height: 0 })),
            transition('void => *', [
                style({ height: 0 }),
                animate(300, style({ height: '*' }))
            ]),
            transition('* => void', [
                style({ height: '*' }),
                animate(100, style({ height: 0 }))
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MenuComponent implements OnDestroy, OnChanges, OnInit, AfterViewInit {
    menu: any;
    previewCategory: any;
    activeCategory: any;
    activeCategoryTab: any;
    isCompactMenu: boolean;
    isMouseIn: boolean;
    closeDebounce: any;
    unassigned: UserMenu[];
    grouped: UserMenuGroup[];
    useStandardMenu: boolean = true;

    constructor(
        private Menu: MenuService,
        private cd: ChangeDetectorRef,
        private PromanState: PromanStateService,
        private Router: Router,
        private Dialog: Dialog,
        @Inject(Store) private store: Store
    ) {
        this.closeDebounce = debounce(() => {
            if (!this.isMouseIn) {
                if (!this.isCompactMenu) this.previewCategory = null;
                this.updateView();
            }
        }, 2000);

    }

    ngOnInit() {

        if (!this.Menu.items.length) this.Menu.init();

        this.Menu.updateActiveElements();

        this.menu = this.Menu.items;

        this.updateActiveElements();

        this.Router.events.pipe(
            filter((event) => (event instanceof NavigationEnd )))
            .subscribe(() => this.updateActiveElements());

        this.updateView();

        this.store.dispatch(loadMinimalMenu());

        this.store.select(getMinimalMenu)
            .subscribe((value) => {
                this.unassigned = value?.unassigned;
                this.grouped = value?.groups;
                if ((this.unassigned && this.unassigned.length > 0) || (this.grouped && this.grouped.length > 0)) this.useStandardMenu = false;
                this.updateView();
            });
    }

    async ngAfterViewInit() {
        await delay(100);
        this.store.select(getCurrUserUiPrefs)
            .subscribe((uiPrefs) => {
                this.isCompactMenu = uiPrefs[UI_COMPACT_MENU];
                this.updateView();
            });

    }

    ngOnChanges(changes: SimpleChanges) {

    }

    ngOnDestroy() {
        this.Menu.destroy();
    }

    isVisibleItems(category: any) {
        return (!this.previewCategory && this.activeCategory === category) || this.previewCategory === category;

    }

    selectCategory(category: any) {
        (this.previewCategory === category) ?
            this.previewCategory = null :
            this.previewCategory = category;

        if (!category.tabs) this.navigateToFirstChildState(category);

    }

    selectUserMenuCategory(category: UserMenuGroup) {
        (this.previewCategory === category) ?
          this.previewCategory = null :
          this.previewCategory = category;

        if (!category.items) this.navigateToFirstUserMenuChildState(category);

    }

    navigateToFirstChildState(category: any) {
        this.PromanState.to(category.state);

        this.activeCategory = category;
        this.activeCategoryTab = category.state;
    }

    navigateToFirstUserMenuChildState(category: UserMenuGroup) {
        this.PromanState.to(category.state);

        this.activeCategory = category;
        this.activeCategoryTab = category.state;
    }

    updateActiveElements = () => {

        if (this.Menu.activeCategory) {
            this.activeCategory = this.Menu.activeCategory;

        }

        if (this.Menu.activeCategoryTab) {
            this.activeCategoryTab = this.Menu.activeCategoryTab;

        }

        this.previewCategory = null;

        this.updateView();

    };

    isVisibleTab = (category: any, tab: any) => {
        if (this.isCompactMenu && !this.previewCategory) return false
        if (tab.state === this.activeCategoryTab) return true;
        if (category === this.previewCategory) return true;

        this.updateView();
    };

    updateView = () => this.cd.markForCheck();

    handleMouseLeave = () => {
        this.isMouseIn = false;

        if (this.isCompactMenu) {
            this.closeDebounce();
        }
    };

    handleMouseEnter = () => {
        this.isMouseIn = true;
        this.closeDebounce.cancel();
    };

    handleUserMenu(menu: UserMenu) {
        if (menu.state.includes(window.location.origin)) {
            const state = '/' + menu.state.split('//')[1].split('/').slice(1).join('/');
            this.Router.navigate([state]);
        } else {
            window.location.href = menu.state;
        }
    }

    toggleStandartMenu() {
        this.useStandardMenu = !this.useStandardMenu;
    }

    checkActiveCategory = (category: any) => {
        return category.tabs?.some((tab: { state: string }) => tab.state === this.activeCategoryTab);
    }

    checkActiveUserMenuCategory = (category: UserMenuGroup) => {
        return category.items?.some((tab: { state: string }) => tab.state === this.activeCategoryTab);
    }

    editMinimalMenu() {
        this.Dialog.open(EditUserMenuItemDialogComponent, {}, { refreshOnClose: true })
            .then(() => {
                this.store.dispatch(loadMinimalMenu());
                setTimeout(() => window.location.reload(), 2000);
            })
    }

}
