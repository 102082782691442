import { enableProdMode } from '@angular/core';
import { environment } from '../environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app-module';

import 'zone.js';

import '../vendor-integrations/moment-duration-format-dev/lib/moment-duration-format';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);

import '@proman/declaration';

