import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from '@proman/rxjs-common';
import { AuthService } from '../services/auth.service';
import { completeJsonRequest, getJsonRequests, pendingReqIncr } from '../store/utils';
import { Store } from '@ngrx/store';

const FILE_UPLOAD_URLS = [
    'api/file/upload',
    'read_from_file',
    '/import',
];

@Injectable({
    providedIn: 'root'
})
export class HttpRequestInterceptor implements HttpInterceptor {
    jsonReqs: string[];

    constructor(
        private Auth: AuthService,
        private store: Store,
    ) {
        this.store.select(getJsonRequests)
            .subscribe((val) => {
                this.jsonReqs = val;
            });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.store.dispatch(pendingReqIncr());

        if (!FILE_UPLOAD_URLS.some((url) => req.url.includes(url))) {
            req = req.clone({
                setHeaders: {
                    'Authorization': this.Auth.getToken() || '',
                    'Session': this.Auth.getSessionToken() || '',
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'X-Requested-With': 'XMLHttpRequest'
                }});
        } else {
            req = req.clone({
                setHeaders: {
                    'Authorization': this.Auth.getToken() || '',
                    'Session': this.Auth.getSessionToken() || '',
                }});
        }

        if (this.jsonReqs.includes(req.url)) {
            req = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                }
            });

            this.store.dispatch(completeJsonRequest({payload: req.url}));

        }

        return next.handle(req);
    }
}
