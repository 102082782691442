import { Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { Dialog } from '../services/dialog.service';
import { Entity } from '@proman/services/entity.service';
import { NotificationsService } from '../../notifications/services/notifications.service';
import { isDefined } from '@proman/utils';
import { PromanStateService } from '../services/proman-state.service';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { getCurrUser } from '@proman/store/curr-user';
import { Store } from '@ngrx/store';
import { UI_COMPACT_MENU, UiPreferencesService } from '@proman/services/ui-preferences.service';

@Component({
    selector: 'pm-tool-btns',
    template: `
        <div class="ToolBtns" fxLayout="column" fxLayoutAlign="center center">
            <span class="NotificationsItem Clickable">
                <ng-container>
                     <pro-btn [theme]="markImportant ? 'accent' : 'primary'"
                             [icon]="'bell'"
                             (onClick)="PromanState.to('UserNotifications')"
                             [tooltip]="'notifications' | translate"></pro-btn>
                </ng-container>
                <span *ngIf="markImportant" class="NotificationsItem--Important">
                    {{ importantCount }}
                </span>

                <ng-container>
                    <pro-btn [theme]="'primary'"
                            [icon]="compactMode ? 'bars' : 'ellipsis-v'"
                            [tooltip]="compactMode ? 'full_menu' : 'compact_menu' | translate"
                            (onClick)="setCompactMode(!compactMode)"></pro-btn>
                </ng-container>
            </span>
        </div>
    `,
    styles: [`
        :host {
            position: absolute;
            bottom: 8px;
            width: 100%;
        }
        .NotificationsItem {
            color: #eee;
            font-weight: bolder;
            font-family: 'Open Sans Regular';
            display: inline-block;
            position: relative;
            user-select: none;
            padding-right: 20px;
        }
        .NotificationsItem--Important {
            position: absolute;
            top: -4px;
            left: 15px;
            display: inline-block;
            border-radius: 50%;
            font-size: 0.75em;
            padding: 0 4px;
        }
    `]
})

export class ToolBtnsComponent implements OnInit {
    currUser: CurrUser;
    markImportant: boolean;
    compactMode: boolean;
    importantCount: number;

    constructor(
        private Dialog: Dialog,
        private Entity: Entity,
        private Notifications: NotificationsService,
        private UiPrefs: UiPreferencesService,
        public PromanState: PromanStateService,
        private store: Store,
    ) {
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
    }

    ngOnInit() {
        this.getImportantNotificationsCount();

        this.compactMode = this.UiPrefs.get(UI_COMPACT_MENU);

        this.Notifications.timeStamp.subscribe(() => this.getImportantNotificationsCount());
    }

    addImportantMark = (response: any) => {

        if (response && isDefined(response.count)) {
            this.importantCount = response.count;
            this.markImportant = this.importantCount > 0;
        }

    };

    setCompactMode = (value: any) => {
        this.compactMode = value;
        this.UiPrefs.set(UI_COMPACT_MENU, value);
    }

    getImportantNotificationsCount() {
        this.Entity.get('notification')
            .search({ 'count': true, 'isRead': false, 'recipient.id' : this.currUser.person.id, })
            .then(this.addImportantMark);
    }
}
