import { Injectable } from '@angular/core';
import { BehaviorSubject } from '@proman/rxjs-common';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root'
})
export class OnlineStatusService {
    isOnline: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(
        private Toast: ToastService,
    ) {
        window.addEventListener('online', this.updateStatus);
        window.addEventListener('offline', this.updateStatus);
    }

    updateStatus = () => {
        const message  = navigator.onLine ? 'server_connection_is_back' : 'internet_connection_lost';
        const type  = navigator.onLine ? 'info' : 'error';

        this.Toast.pop(type, message);

        this.isOnline.next(navigator.onLine);
    }

}
