import { tap } from '@proman/rxjs-common';
import { Observable } from '@proman/rxjs-common';
import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest, HttpResponse,
} from '@angular/common/http';
import { ToastService } from '../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FilterService } from '../services/filter.service';
import { Location } from '@angular/common';
import { PreferencesService } from '../services/preferences.service';
import { isArray } from '../utils';
import { Store } from '@ngrx/store';
import { setUpdateStatus } from '../store/system-options';
import { pendingReqDecr } from '../store/utils';

@Injectable({ providedIn: 'root' })
export class HttpResponseInterceptor implements HttpInterceptor {
    skippedUrls: string[] = [
        'api/employee_contract/get', // employee_contract/get/?employee.id=1047&join%5B%5D=employeeContractWorkDayType
        '&resourceBookings.type=employee&join%5B%5D=resourceBookings&join%5B%5D=resourceBookings.employee', // maintenance/get/?id=443394&resourceBookings.type=employee&join%5B%5D=resourceBookings&join%5B%5D=resourceBookings.employee
        'card', // api/card/get/1047
        'user/get?person.id=',  // api/user/get?person.id=2322
        '&join%5B%5D=order&join%5B%5D=order.shipments&join%5B%5D=order.manager&join%5B%5D=order.files&join%5B%5D=order.tags&join%5B%5D=tags&partialJoin%5Border.customer%5D%5B%5D=name', // https://new-3t.proman.lt/api/production/get/?operations.id=465066&join%5B%5D=order&join%5B%5D=order.shipments&join%5B%5D=order.manager&join%5B%5D=order.files&join%5B%5D=order.tags&join%5B%5D=tags&partialJoin%5Border.customer%5D%5B%5D=name,
        'user/get/?person.id=', // /api/user/get/?person.id=3
        'person_email/get?person.id', // /api/person_email/get?person.id=1974&join%5B%5D=person
        'token_user/get?token=', // token_user/get?token=12345&join
        'template/render', // template/render
        'element/render', // element/render
        'api/production/get?operations.id=', // order operations calendar error for not found production
        'ping',
    ];

    constructor(
        private Router: Router,
        private Toast: ToastService,
        private Translate: TranslateService,
        private Filter: FilterService,
        private Location: Location,
        private Prefs: PreferencesService,
        private store: Store,
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(tap((event) => {
            if (event instanceof HttpResponse) {
                this.store.dispatch(pendingReqDecr());
            }
        }, (errResponse) => {
            this.store.dispatch(pendingReqDecr());

            let data = errResponse.error;
            let status = errResponse.status;

            if (status === 200) {
                return Promise.resolve(errResponse && errResponse.error && errResponse.error.text);
            } else if (status === 401) {
                if (!this.Location.path().includes('login')) this.Prefs.intendedLocation(this.Location.path());

                // no redirect if shop login
                if (this.Location.path().includes('my-account')) return;

                return this.Location.path().includes('/kitchen') ?
                    this.Router.navigate(['/kitchen/login']) :
                    this.Router.navigate(['/login']);
            } else if (status === 503 || status === 502) {
                this.store.dispatch(setUpdateStatus({ payload: true }));

            } else if (status === 400) {
                if (!this.isUrlSkipped(req.url)) {
                    let message = '';
                    if (data[2] && isArray(data[2])) message = data[2].join(',');
                    console.log('400', message, data);
                    this.Toast.pop('error', `${data[1]} ${message}`);
                }

            } else  if (status !== 401) {

                if ( data && data.exception && data.exception.message) {
                    if (!this.isUrlSkipped(req.url)) this.Toast.pop('error', this.Translate.instant(data.exception.message));

                } else if (isArray(data)) {
                    this.Toast.pop('error', data[1] + `${isArray(data[2] ) ?  data[2].join(' ') : ''}`);

                }

            }

        }));

    }

    isUrlSkipped = (reqUrl: string) => this.skippedUrls.some((url: string) => reqUrl.includes(url));

}
