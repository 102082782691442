import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { resourcesConfig } from '@proman/resources';

const WIDGETS = resourcesConfig.widget.params.widgetTypes;

@Component({
    selector: 'pm-add-widget',
    template: `
        <pro-dialog-title title="add_widget"></pro-dialog-title>
        <div mat-dialog-content>
            <table class="Table">
                <tr *ngFor="let widget of widgets">
                    <td>{{ 'widget_title_' + widget | translate }}</td>
                    <td>
                        <pro-btn
                                (onClick)="dialogRef.close(widget)"
                                icon="check-circle"
                                theme="accent"></pro-btn>
                    </td>
                </tr>
            </table>
        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class AddWidgetDialogComponent {
    widgets: any[] = Object.keys(WIDGETS);

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private dialogRef: MatLegacyDialogRef<AddWidgetDialogComponent>,
    ) {

    }

}
