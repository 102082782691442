import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MenuService } from '@frontend/shared/services/menu.service';
import { PromanMenuItem } from '@proman/interfaces/object-interfaces';
import { hasUtfSubstring } from '@proman/utils';
import { FilterService } from '@proman/services/filter.service';

@Component({
  selector: 'pm-dashboard-quick-search-dialog',
  template: `
        <pro-dialog-title title="search"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="center center">
                <pm-txt [value]="query"
                        [config]="{ label: 'search', autofocus: true, debounce: 0 }"
                        (onChange)="setQuery($event)"
                ></pm-txt>

            </div>

            <pm-label *ngIf="filteredMenu.length">{{ 'menu' | translate }}</pm-label>
            <div class="List">
                <ng-container *ngFor="let item of filteredMenu">
                    <ng-container *ngIf="item.type">
                        <div class="List-row FontBold"> {{ item.name | translate }}</div>
                        <div *ngFor="let tab of item.tabs" class="List-row LeftMargin Clickable" [routerLink]="tab.state | pmSref">{{ tab.name | translate }}</div>
                    </ng-container>

                    <ng-container *ngIf="item.state">
                        <div class="List-row  Clickable" [routerLink]="item.state | pmSref">{{ item.name | translate}}</div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <pro-dialog-actions></pro-dialog-actions>

  `
})

export class DashboardQuickSearchDialogComponent {
    query: string = '';

    menu: any[];
    filteredMenu: any[];
    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Menu: MenuService,
        private Filter: FilterService,
        private dialogRef: MatLegacyDialogRef<DashboardQuickSearchDialogComponent>,
    ) {
        this.menu = this.Menu.get();
        this.filteredMenu = this.menu;
    }

    setQuery(value: string) {
        this.query = value;

        this.filterItems();
    }

    filterItems() {
        this.filteredMenu = this.menu.filter((item) => {
           if (item?.tabs) {
               return item.tabs.some((tab: PromanMenuItem) => hasUtfSubstring(this.Filter.translate(tab.name), this.query))
           } else {
               hasUtfSubstring(this.Filter.translate(item.name), this.query)
           }
        });

        this.filteredMenu.forEach((item) => {
            if (item?.tabs) {
                item.tabs = item.tabs.filter((tab: PromanMenuItem) => hasUtfSubstring(this.Filter.translate(tab.name), this.query));
            }
        })
    }
}
