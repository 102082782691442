import { TitleService } from './shared/services/title.service';
import { ToolbarService } from './shared/services/toolbar.service';
import { ModelService } from '@proman/services/model.service';
import { MenuService } from './shared/services/menu.service';
import { Search } from '@proman/services/search.service';
import { Dialog } from './shared/services/dialog.service';
import { AppLoader } from '@proman/services/app-loader.service';
import { LanguageConfigService } from '@proman/services/language-config.service';
import { DateTimeFormatService } from '@proman/services/date-time-format.service';
import { ImagePathService } from '@proman/services/image-path.service';
import { CursorLoadingService } from '@proman/services/cursor-loading.service';
import { ProductionsService } from './production/services/productions.service';
import { OrderProductsService } from './orders/services/order-products.service';
import { ProductsService } from './products/services/products.service';
import { OrdersService } from './orders/services/orders.service';
import { UploaderService } from '@proman/services/uploader.service';
import { FilterService } from '@proman/services/filter.service';
import { UniqueValidatorService } from '@proman/services/unique-validator.service';
import { ProductionOperationService } from './products/services/production-operation.service';
import { EmployeesService } from './employees/services/employees.service';
import { NotificationsService } from './notifications/services/notifications.service';
import { ZplEntityCodesService } from './qr/services/zpl-entity-codes.service';
import { QrCodeUrlService } from './qr/services/qr-code-url.service';
import { BarcodeScannerService } from '@proman/services/barcode-scanner.service';
import { FilePreviewService } from '@proman/services/file-preview.service';
import { InvoicesService } from './accounting/services/invoices.service';
import { PermissionsService } from '@proman/services/permissions.service';
import { CurrenciesService } from '@proman/services/currencies.service';
import { PersonalEventService } from './events/services/personal-event.service';
import { CardLoginListenerService } from './shared/services/card-login-listener.service';
import { TableEntitySearchParametersService } from '@proman/table/services/table-entity-search-parameters.service';
import { QueryService } from '@proman/services/query.service';
import { EventsService } from './events/services/events.service';
import { ObservablesService } from '@proman/services/observables.service';
import { UserResolver } from './app-resolvers';
import { TrueResolver } from './shared/services/shared.resolvers';
import { SaleOpportunitiesService } from './projects/services/sale-opportunities.service';
import { ChartsService } from './dynamic_tables/services/charts.service';
import { WarehousesService } from './workplaces/warehouses.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { WarningsService } from './shared/services/warnings.service';
import { ServicesService } from './products/services/services.service';
import { PromanStoreModule } from '@proman/store/proman-store.module';
import { OcrService } from './shared/services/ocr.service';

const PROVIDERS = [
    ToolbarService,
    TitleService,
    ModelService,
    MenuService,
    Search,
    Dialog,
    AppLoader,
    LanguageConfigService,
    DateTimeFormatService,
    ImagePathService,
    CursorLoadingService,
    ProductionsService,
    OrdersService,
    OrderProductsService,
    ProductsService,
    ServicesService,
    UploaderService,
    FilterService,
    UniqueValidatorService,
    ProductionOperationService,
    EmployeesService,
    NotificationsService,
    QrCodeUrlService,
    ZplEntityCodesService,
    BarcodeScannerService,
    FilePreviewService,
    InvoicesService,
    PermissionsService,
    CurrenciesService,
    PersonalEventService,
    CardLoginListenerService,
    TableEntitySearchParametersService,
    QueryService,
    EventsService,
    ObservablesService,
    SaleOpportunitiesService,
    ChartsService,
    WarehousesService,
    WarningsService,
    OcrService,

    UserResolver,
    TrueResolver,
];

@NgModule({
    imports: [
        PromanStoreModule,
    ],
    providers: PROVIDERS,
    exports: []
})

export class AppServicesModule {
    static forRoot(): ModuleWithProviders<AppServicesModule> {
        return {
            ngModule: AppServicesModule,
            providers: PROVIDERS
        };
    }
}
