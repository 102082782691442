import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { FilterService } from '@proman/services/filter.service';
import { DragulaService } from 'ng2-dragula';
import { getIndexByProperty } from '@proman/utils';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import $ from 'jquery';
import { Store } from '@ngrx/store';
import {getCurrUser} from "@proman/store/curr-user";

@Component({
    selector: 'pm-dashboard-settings',
    template: `
        <pro-dialog-title title="settings"></pro-dialog-title>
        <div mat-dialog-content fxLayoutAlign="column" pmPadding>

            <div pmPadding
                 [dragula]="dragulaId"
                 [dragulaModel]="items">

                <pro-no-records *ngIf="!items.length"></pro-no-records>

                <mat-expansion-panel *ngFor="let item of items; let $index = index;"
                                     [expanded]="item._open"
                                     (opened)="item._open = true">
                    <mat-expansion-panel-header>
                        <div fxLayout="row" fxLayoutAlign="space-between center">

                            <pro-move-handle [class]="'Dashboards-moveHandle'"></pro-move-handle>

                            <div fxLayout="row" fxLayoutAlign="center center">
                                <fa [name]="item.icon"></fa>
                                <pm-txt class="LeftPadding"
                                        [value]="item.name"
                                        [config]="{}"
                                        (onChange)="changeName(item, $event)"
                                        proClickStopPropagation></pm-txt>
                            </div>

                            <pro-btn
                                    proClickStopPropagation
                                    (onClick)="delete($index)"
                                    icon="trash"
                                    theme="warn"></pro-btn>

                        </div>
                    </mat-expansion-panel-header>

                    <pm-icon-select [value]="item.icon"
                                    [toggled]="true"
                                    (onChange)="changeIcon(item, $event)"></pm-icon-select>

                </mat-expansion-panel>
            </div>

        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class DashboardSettingsDialogComponent implements OnInit, OnDestroy {
    items: any = [];

    currUser: CurrUser;
    dashboardEntity: any;
    dragulaId: any;

    isMovable: any;

    dragItem: any;
    subscriberDrop: any;
    subscriberDrag: any;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private Filter: FilterService,
        private Dragula: DragulaService,
        private store: Store,
        private dialogRef: MatLegacyDialogRef<DashboardSettingsDialogComponent>,

    ) {
      this.store.select(getCurrUser).subscribe((value) => this.currUser = value);
        this.dashboardEntity = this.Entity.get('dashboard');

        this.items = this.data.dashboards.map((item: any) => {

            try {
                item.name = item.config ? this.Filter.translate(JSON.parse(item.config).name || (JSON.parse(item.config).state || '').toLowerCase()) : item.name;
            } catch (e) {

            }

            return item;
        });

    }

    ngOnInit() {
        this.initDragula();
    }

    ngOnDestroy() {
        this.subscriberDrag.unsubscribe();
        this.subscriberDrop.unsubscribe();
    }

    initDragula() {
        this.dragulaId = 'dashboardSettings-' + new Date().valueOf();

        this.Dragula.createGroup(this.dragulaId, {

            moves: (el: any, source: any, handle: any) => {
                let element = $(handle);

                const isMove = (element: any) => element.hasClass('Dashboards-moveHandle');

                this.isMovable = isMove(element) || isMove(element.parent()) || isMove(element.parent().parent());

                return this.isMovable;
            },
        });

        this.subscriberDrag = this.Dragula.drag(this.dragulaId).subscribe(({ name, el, source }) => {

            if (this.isMovable) {
                let index = [].slice.call(el.parentElement.children).indexOf(el);

                this.dragItem = Object.assign({}, this.items[index]);

            }

        });

        this.subscriberDrop = this.Dragula.drop(this.dragulaId).subscribe(({ name, el, source }) => {

            if (this.isMovable) {
                let index = [].slice.call(el.parentElement.children).indexOf(el);
                let position = getIndexByProperty(this.items, 'id', this.items[index].id);

                this.dashboardEntity
                    .reposition({ positionId: this.dragItem.id, positionAt: position, 'employee.id': this.currUser.person.id });

                this.dragItem = null;

            }

        });

    }

    delete($index: number) {
        return this.dashboardEntity
            .remove({ id: this.items[$index].id })
            .then(() => this.items.splice($index, 1));

    };

    changeIcon = (item: any, icon: any) => {
        this.dashboardEntity.update({ id: item.id, icon })
            .then(() => {
                item.icon = icon;
                item._open = false;
            });

    };

    changeName(item: any, value: string) {
        let request: any = { id: item.id };
        let itemConfig: any = JSON.parse(item.config);

        if (item.config) {
            item.name = value;
            itemConfig.name = value;
            request.config = JSON.stringify(itemConfig);

        } else {
            request.name = value;
            item.name = value;

        }

        this.dashboardEntity.update(request);
    };

}
