import { of as observableOf } from 'rxjs';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MissingTranslationHandler } from '@ngx-translate/core';
import { PromanModule } from '@proman/proman.module';

// modules
import { AppRoutingModule } from './app-routes';

// Components
import { AppComponent } from './app.component';
import { MainComponent } from './core/components/main.component';
import { AppServicesModule } from './app-services';
import { AppInitService } from '@proman/services/app-init.service';
import { HttpResponseInterceptor } from '@proman/interceptors/http-response-interceptor';
import { DashboardsModule } from './dashboards/dashboards.module';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { HammerGestureConfig } from '@angular/platform-browser';
import { LoginModule } from './login/login.module';
import { MenuModule } from './menu/components/menu.module';
import { DefaultRouteComponent } from '@frontend/shared/components/default-route.component';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { SentryModule } from '@proman/sentry';
import { EffectsModule } from '@ngrx/effects';
import { GuardsModule } from './guards/guards.module';
import { HttpRequestInterceptor } from '@proman/interceptors/http-request-interceptor';
import { SystemOptionsFrontendEffects } from './store/effects/system-options-frontend.effects';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { ParametersStoreModule } from '@proman/parameters-store.module';
import { InactivityInterceptor } from '@proman/interceptors/inactivity-interceptor';
import { LanguageConfigService } from '@proman/services/language-config.service';
import { CustomMissingTranslationHandler } from '@proman/shared/language/custom-missing-traslation-handler';

export class CustomTranslateLoader implements TranslateLoader {

    constructor(private AppInit: AppInitService) {
    }

    getTranslation(lang: string): any {
        return observableOf(this.AppInit.translations[this.AppInit.currentLang]);
    }

}

export function getTranslations(InitService: AppInitService ) {
    return function() {
        return InitService.initialize();
    };
}

@Injectable()
export class CustomRouteReuseStrategy extends RouteReuseStrategy {
    shouldDetach(route: ActivatedRouteSnapshot): boolean { return false; }
    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}
    shouldAttach(route: ActivatedRouteSnapshot): boolean { return false; }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle { return null !; }
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        const futureComponent: any = future.component;
        const sameRoute = future.routeConfig === curr.routeConfig;
        const forceRerender = futureComponent && futureComponent.toString().includes('rerenderOnReuse');

        return sameRoute && (!futureComponent || !forceRerender);
    }
}

@NgModule({
    imports: [
        CommonModule,
        SentryModule.forRoot({ project: 'frontend' }),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
            useDefaultLang: false
        }),
        ToastrModule.forRoot({
            timeOut: 50000,
            extendedTimeOut: 25000,
            positionClass: 'toast-bottom-right',
            newestOnTop: true,
            iconClasses: {
                error: 'toast-pm-error',
                info: 'toast-pm-info',
                success: 'toast-pm-success',
                warning: 'toast-pm-warning',
            },
            preventDuplicates: true,
        }),
        DashboardsModule,
        PromanModule,
        ParametersStoreModule,
        EffectsModule.forFeature([SystemOptionsFrontendEffects]),
        MatLegacyDialogModule,
        AppServicesModule.forRoot(),
        GuardsModule.forRoot(),
        MatLegacyProgressBarModule,
        LoginModule,
        MenuModule,
    ],
    declarations: [
        MainComponent,
        DefaultRouteComponent,
        AppComponent,
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: APP_INITIALIZER, useFactory: getTranslations, deps: [AppInitService], multi: true },
        { provide: TranslateLoader, useClass: CustomTranslateLoader, deps: [AppInitService] },
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: InactivityInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
        AppInitService,
        LanguageConfigService,
        Location,
    ],
    bootstrap: [AppComponent]
})

export class AppModule {

}
